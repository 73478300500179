import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiMapPin, FiMail, FiPhone, FiInstagram, FiFacebook, FiArrowUp, FiMousePointer } from "react-icons/fi";
import { RiTwitterXFill } from "react-icons/ri";

import './styles/footer.css';

import logo from '../assets/images/Logo.jpeg';
import Swal from 'sweetalert2';

const Footer = () => {
	const navigate = useNavigate();
	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const onNavigate = (path) => {
		navigate(path);
	};

	const linkOnAvailable = () => {
		Swal.fire({
			title: 'working on it!',
			text: 'The link is not available yet.',
			icon: 'info',
			confirmButtonText: 'Ok',
		});
	};

	return (
		<section className='footer'>
			<div className='container'>
				<div className='top'>
					<FiArrowUp class='topIcon' onClick={() => scrollTop()} />
				</div>
				<div className='right'>
					<div className='tabs'>
						<div
							className='logo'
							onDoubleClick={() => onNavigate('/login')}
						>
							<img src={logo} alt='logo' />
						</div>
					</div>
					<div className='tabs'>
						<h3>Get In Touch</h3>
						<ul>
							<li><FiMapPin class='icon' /> 237 Bosworth Street, Alrode South, Alberton</li>
							<li
								onClick={() => window.open('mailto:info@rayconpoolcovers.com', '_blank')}
							>
								<FiMail class='icon' /> info@rayconpoolcovers.com</li>
							<li><FiPhone class='icon' /> 072 172 5027 / 010 023 4773</li>
						</ul>
					</div>
				</div>
				<div className='right'>
					<div className='tabs'>
						<h3>Social Media</h3>
						<ul>
							<li onClick={() => window.open('https://www.facebook.com/profile.php?id=100065001754820', '_blank')}> <FiFacebook class='icon' /> Facebook</li>
							<li onClick={() => window.open('https://www.instagram.com/raycontarps/', '_blank')}><FiInstagram class='icon' /> Instagram</li>
							<li onClick={() => linkOnAvailable()}> <RiTwitterXFill class='icon' /> Twitter/X</li>
							<li onClick={() => window.open('https://raycontarp.co.za/', '_blank')}> <FiMousePointer class='icon' /> Raycon Tarps</li>
						</ul>
					</div>
					<div className='tabs'>
						<h3>Pages</h3>
						<ul>
							<li onClick={() => onNavigate('/')}>Home</li>
							<li onClick={() => onNavigate('/shop')}>Shop</li>
							<li onClick={() => onNavigate('/about')}>About Us</li>
							<li onClick={() => onNavigate('/contact')}>Contact Us</li>
						</ul>
					</div>
				</div>
			</div>
			<footer>
				<p className='text'>
					© {new Date().getFullYear()} Raycon Pool Covers. All rights reserved |
					Built by{' '}
					<span
						onClick={() =>
							window.open('https://syntaxtechsolutions.com/', '_blank')
						}
					>
						{' '}
						Syntax Tech Solutions
					</span>
				</p>
			</footer>
		</section>
	);
};

export default Footer;
